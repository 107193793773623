const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach((key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key])));
    }
    return urlWithPrefix;
};

export const ADMIN_GOOGLE_LOGIN_URL = () => UrlParamsReplace("/auth/google?state=admin");

//for login

export const VERIFY_TOKEN_URL = () => UrlParamsReplace("/token/verify");

//users
export const GET_USERS_URL = (offset, limit, search, status, name, order, createStartDate, createEndDate, updateStartDate, updateEndDate) =>
    UrlParamsReplace(
        "/users?offset=:offset&limit=:limit&search=:search&status=:status&name=:name&order=:order&createStartDate=:createStartDate&createEndDate=:createEndDate&updateStartDate=:updateStartDate&updateEndDate=:updateEndDate",
        {
            offset,
            limit,
            search,
            status,
            name,
            order,
            createStartDate,
            createEndDate,
            updateStartDate,
            updateEndDate,
        }
    );

export const GET_ALL_USERS_URL = () => UrlParamsReplace("/allUsers");

export const GET_USER_URL = (id) => UrlParamsReplace("/user/:id", { id });

export const UPDATE_USER_URL = (id) => UrlParamsReplace("/user/:id", { id });

export const GET_CUSTOMER_TRANSACTIONS = (offset, limit, search, status, name, order, userId, merchantId, orderStartDate, orderEndDate, cashbackStartDate, cashbackEndDate) =>
    UrlParamsReplace(
        "/transactions?offset=:offset&limit=:limit&search=:search&status=:status&name=:name&order=:order&userId=:userId&merchantId=:merchantId&orderStartDate=:orderStartDate&orderEndDate=:orderEndDate&cashbackStartDate=:cashbackStartDate&cashbackEndDate=:cashbackEndDate",
        {
            offset,
            limit,
            search,
            status,
            name,
            order,
            userId,
            merchantId,
            orderStartDate,
            orderEndDate,
            cashbackStartDate,
            cashbackEndDate,
        }
    );
export const GET_CUSTOMER_WALLET_TRANSACTIONS = (
    offset,
    limit,
    search,
    status,
    name,
    order,
    userId,
    merchantId,
    orderStartDate,
    orderEndDate,
    walletHistoryStartDate,
    walletHistoryEndDate
) =>
    UrlParamsReplace(
        "/customerWalletTransactions?offset=:offset&limit=:limit&search=:search&status=:status&name=:name&order=:order&userId=:userId&merchantId=:merchantId&orderStartDate=:orderStartDate&orderEndDate=:orderEndDate&walletHistoryStartDate=:walletHistoryStartDate&walletHistoryEndDate=:walletHistoryEndDate",
        {
            offset,
            limit,
            search,
            status,
            name,
            order,
            userId,
            merchantId,
            orderStartDate,
            orderEndDate,
            walletHistoryStartDate,
            walletHistoryEndDate,
        }
    );

//merchants

export const GET_MERCHANTS_URL = (offset, limit, search, status, name, order, startDate, endDate, categoryName) =>
    UrlParamsReplace(
        "/merchants?offset=:offset&limit=:limit&search=:search&status=:status&name=:name&order=:order&startDate=:startDate&endDate=:endDate&categoryName=:categoryName",
        {
            offset,
            limit,
            search,
            status,
            name,
            order,
            startDate,
            endDate,
            categoryName: encodeURIComponent(categoryName),
        }
    );

export const GET_MERCHANT_DETAILS_URL = (id) => UrlParamsReplace("/merchant/:id", { id });

export const UPDATE_MERCHANT_URL = (id) => UrlParamsReplace("/merchant/:id", { id });

export const GET_MERCHANTS_NAME_URL = () => UrlParamsReplace("/merchantsName");
export const ADD_MERCHANT_URL = () => UrlParamsReplace("/merchant");

//aws
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace("/aws/get-upload-pre-signed-url");

//merchant-categories
export const GET_MERCHANT_CATEGORIES_URL = (id, offset, limit, search, name, order) =>
    UrlParamsReplace("/merchant/:id/categories?offset=:offset&limit=:limit&search=:search&name=:name&order=:order", {
        id,
        offset,
        limit,
        search,
        name,
        order,
    });

export const CREATE_MERCHANT_CATEGORY_URL = (id) => UrlParamsReplace("/merchant/:id/category", { id });

export const UPDATE_MERCHANT_CATEGORY_URL = (merchantId, categoryId) => UrlParamsReplace("/merchant/:merchantId/category/:categoryId", { merchantId, categoryId });

export const GET_MERCHANT_CATEGORY_URL = (merchantId, categoryId) => UrlParamsReplace("/merchant/:merchantId/category/:categoryId", { merchantId, categoryId });

export const DELETE_MERCHANT_CATEGORY_URL = (merchantId, categoryId) => UrlParamsReplace("/merchant/:merchantId/category/:categoryId", { merchantId, categoryId });

//coupon
export const GET_COUPONS_URL = (id, offset, limit, search, name, order) =>
    UrlParamsReplace("/merchants/:id/coupons?offset=:offset&limit=:limit&search=:search&name=:name&order=:order", {
        id,
        offset,
        limit,
        search,
        name,
        order,
    });

export const CREATE_COUPON_URL = (id) => UrlParamsReplace("/merchant/:id/coupon", { id });

export const UPDATE_COUPON_URL = (merchantId, couponId) => UrlParamsReplace("/merchant/:merchantId/coupon/:couponId", { merchantId, couponId });

export const GET_COUPON_URL = (merchantId, couponId) => UrlParamsReplace("/merchant/:merchantId/coupon/:couponId", { merchantId, couponId });

export const DELETE_COUPON_URL = (merchantId, couponId) => UrlParamsReplace("/merchant/:merchantId/coupon/:couponId", { merchantId, couponId });

export const UPDATE_BANNER_IMAGE_URL = (id) => UrlParamsReplace("/banner-image/:id", { id });

export const UPLOAD_BANNER_IMAGE_URL = () => UrlParamsReplace("/banner-image");

export const GET_BANNER_IMAGES_URL = (offset, limit, search, status, name, availableForAll, order, startDate, endDate, merchantId, bannerType) =>
    UrlParamsReplace(
        "/banner-images?offset=:offset&limit=:limit&search=:search&status=:status&availableForAll=:availableForAll&name=:name&order=:order&startDate=:startDate&endDate=:endDate&merchantId=:merchantId&bannerType=:bannerType",
        {
            offset,
            limit,
            search,
            status,
            name,
            availableForAll,
            order,
            startDate,
            endDate,
            merchantId,
            bannerType,
        }
    );
export const GET_BANNER_IMAGE_URL = (id) => UrlParamsReplace("/banner-image/:id", { id });
export const GER_ACTIVE_IMAGE_URL = () => UrlParamsReplace("/active-banner-image");

//settings
export const GET_WALLET_SETTING_URL = () => UrlParamsReplace("/walletSetting");
export const UPDATE_WALLET_SETTING_URL = (id) => UrlParamsReplace("/walletSettings/:id", { id });

//transactions
export const GET_TRANSACTION_URL = (orderId) => UrlParamsReplace("/transactions/:orderId", { orderId });

export const ADD_TRANSACTION_URL = () => UrlParamsReplace("/walletHistory");
export const ADD_CASHBACK_URL = () => UrlParamsReplace("/addCashback");
export const GET_TRANSACTION_BY_ID_URL = (id) => UrlParamsReplace("/transaction/:id", { id });
export const REVERSE_TRANSACTION_URL = () => UrlParamsReplace("/reverseTransaction");
export const GET_WALLET_TRANSACTION_BY_ID_URL = (id) => UrlParamsReplace("/walletHistory/:id", { id });

export const GET_INDIVIDUAL_CUROMER_TRANSACTION_URL = (orderId, userId) => UrlParamsReplace("/customerTransactions/:orderId/:userId", { orderId, userId });

//clickActivities
export const GET_CLICK_ACTIVITIES_URL = (offset, limit, search, merchantId, userId, orderStartDate, orderEndDate, clickOrigin, name, order) =>
    UrlParamsReplace(
        "/clickActivities?offset=:offset&limit=:limit&search=:search&merchantId=:merchantId&userId=:userId&orderStartDate=:orderStartDate&orderEndDate=:orderEndDate&clickOrigin=:clickOrigin&name=:name&order=:order",
        {
            offset,
            limit,
            search,
            merchantId,
            userId,
            orderStartDate,
            orderEndDate,
            clickOrigin,
            name,
            order,
        }
    );

//referral

export const CREATE_REFERRAL_CODE_URL = () => UrlParamsReplace("/referral-code");
export const GET_PAGINATED_REFERRAL_CODES = (offset, limit, search, isActive, name, order, expiryStartDate, expiryEndDate) =>
    UrlParamsReplace(
        "/referral-code?offset=:offset&limit=:limit&search=:search&isActive=:isActive&name=:name&order=:order&expiryStartDate=:expiryStartDate&expiryEndDate=:expiryEndDate",
        {
            offset,
            limit,
            search,
            isActive,
            name,
            order,
            expiryStartDate,
            expiryEndDate,
        }
    );
export const GET_REFERRAL_DETAILS = (id) => UrlParamsReplace("/referralCode/:id", { id });
export const UPDATE_REFERRAL_CODE_URL = (id) => UrlParamsReplace("/referralCode/:id", { id });
export const GET_REFFERAL_DETAILS_BY_CODE = (code) => UrlParamsReplace("/referralCode?code=:code", { code });

//category

export const GET_ALL_CATEGORIES_NAME = () => UrlParamsReplace("/categoriesName");
