import Icon from "../atoms/Icon";
import Text from "../atoms/Text";
import Button from "../atoms/Button";
import { useRouter } from "next/router";

const LoginWithGoogleTemplate = ({ googleLoginUrl }) => {

  const openLogin = (link) => {
    window.open(link, "_self");
  };

  return (
    <div className="w-[411px] mx-auto md:my-10 text-center">
      <Icon
        iconSrc="/images/KashKounter.png"
        iconWidth="278"
        iconHeight="70"
        iconAlt="logo"
        className=""
      />
      <div className="flex justify-center border rounded-lg h-[418px]">
        <div className="self-center text-center px-14">
          <Text className="font-semibold text-lg">Welcome Back!</Text>
          <Text className="text-sm font-normal py-3 mb-7">
            Kindly login with your google account to continue
          </Text>
          <Button
            className="py-0 mx-auto px-2 rounded-full border-neutral-300 !outline-neutral-300 hover:bg-neutral-50 bg-neutral-50  text-black"
            style="ghost"
            icon="/images/google-logo.svg"
            iconPosition="left"
            label=" Sign in with Google"
            onClick={() => {
              openLogin(googleLoginUrl);
            }}
          />
        </div>
      </div>
      <Text className="mt-3 text-center text-[#6B6B80] font-light text-sm">
        Copyright © 2023, Kash Kounter. All rights reserved.
      </Text>
    </div>
  );
};

export default LoginWithGoogleTemplate;
